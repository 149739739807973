import React from 'react';
import cn from 'classnames';

import styles from './sidebar.scss';

interface IProps {
  abbreviation: string,
  onClick: (event) => void,
  displayName: string,
  mobileViewText: string,
  isActive: boolean,
  children?: React.ReactNode,
}

const SectionItem = React.memo((props: IProps) => (
  <div
    className={ cn(styles.sectionItem, { [styles.active]: props.isActive }) }
    onClick={ !props.isActive ? props.onClick : undefined }
    data-test="sidebarSectionItem"
  >
    <span className={ styles.itemName } data-test="sidebarSectionItemName">{ props.displayName }</span>
    <span className={ styles.itemAbbreviation }> ({ props.abbreviation })</span>
    <span className={ styles.itemMobileViewName }>{ props.mobileViewText }</span>
    { props.children }
  </div>
));

export default SectionItem;
