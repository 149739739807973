import { validateRequiredFieldWithArrayValue } from '@/ui/shared/components/Form/validators/validateRequiredFieldWithArrayValue';
import { validateRecaptchaVerified } from '@/ui/shared/components/Form/validators/validateRecaptchaVerified';
import { groupValidators } from '@/ui/shared/components/Form/validators/helpers';
import { validateEmail } from '@/ui/shared/components/Form/validators/user/validateEmail';

const email = { fieldName: 'email', fieldCaption: 'Email' };
const comments = { fieldName: 'comments', fieldCaption: 'Comments', isPlural: true };

export default (values) => groupValidators(
  validateEmail({ ...email, value: values.email }),
  validateRequiredFieldWithArrayValue({ ...comments, value: values.comments }),
  validateRecaptchaVerified({ fieldName: 'recaptcha', value: values.recaptcha }),
);
