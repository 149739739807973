import React, { useEffect } from 'react';
import Tool from '../Content/Tool';
import HelperTooltip from './HelperTooltip';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import HeaderLinkItem from '@/finsight/ui/common/components/header/Header/HeaderLinkItem';
import styles from '@/finsight/ui/common/components/header/Header/MarketDataMenu/Content/content.scss';
import profileStyles from '@/finsight/ui/common/components/header/Header/MarketDataMenu/Content/Profile/profile.scss';
import {
  ACTIVE_EQUITIES_MAPPING,
} from '@/finsight/ui/common/components/header/Header/MarketDataMenu/Sidebar/constants';

const Equities = () => {
  const {
    setIsMobileMenuOpen,
    setIsMarketDataMenuOpen,
  } = useHeaderMenuContext();

  const onToolClick = () => {
    setIsMobileMenuOpen(true);
    setIsMarketDataMenuOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={ styles.contentContainer }
      data-test="marketDataMenuEquities"
    >
      <HelperTooltip />
      <div className={ styles.row }>
        <div className={ styles.toolsTitle }>
          United States Equity Tools (Via www.capedge.com)
        </div>
      </div>
      <div className={ styles.subRow } data-test="marketMenuTools">
        <div className={ styles.toolsWrp }>
          { ACTIVE_EQUITIES_MAPPING.tools.map((item) => (
            <Tool
              key={ item.id }
              /* @ts-ignore */
              onToolClick={ onToolClick }
              { ...item }
            />
          )) }
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles.sectorsTitle }>
          Sector Profiles
        </div>
      </div>
      <div className={ styles.equityRow }>
        { Object.keys(ACTIVE_EQUITIES_MAPPING.sectorsMenu).map((key) => (
          <div key={ key } className={ styles.col }>
            { ACTIVE_EQUITIES_MAPPING.sectorsMenu[key].map((item) => (
              <HeaderLinkItem
                isExternal
                className={ profileStyles.childProfile }
                key={ item.id }
                linkTo={ item.link }
                withIcon
              >
                { item.name }
              </HeaderLinkItem>
            )) }
          </div>
        )) }
      </div>
    </div>
  );
};

export default Equities;
