import cn from 'classnames';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import finsightLogo from '@/ui/assets/logo/finsightLogo.inline.svg';
import styles from './footerLogo.scss';

interface IProps {
  className?: string,
}

const FooterLogo = (props: IProps) => (
  <a href={ finsightWebUrl.getUrl() }>
    <i
      className={ cn(styles.logo, { [props.className]: props.className }) }
      dangerouslySetInnerHTML={ { __html: finsightLogo } }
    />
  </a>
);

export default FooterLogo;
