import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { useDIContext } from '@/Framework/DI/DIContext';
import BreadcrumbsRepository from '@/finsight/infrastructure/repository/BreadcrumbsRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import getIssuerBreadcrumbs from '@/finsight/ui/components/issuer/getIssuerBreadcrumbs';
import IBreadcrumb from '@/finsight/domain/vo/Breadcrumbs';

const useBreadcrumbs = (serverBreadcrumbs) => {
  const { container } = useDIContext();
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(serverBreadcrumbs);
  const requestService = container.get(BreadcrumbsRepository);
  const { query } = useRouter();

  const getIssuerBreadcrumbsReq = useCallback(async (companyViewId) => {
    try {
      const data = await requestService.getBreadcrumbs(companyViewId);
      setBreadcrumbs(getIssuerBreadcrumbs(query, data.parent, data.issuer));
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    }
  }, [serverBreadcrumbs]);

  useEffect(() => {
    if (serverBreadcrumbs?.hasError) {
      getIssuerBreadcrumbsReq(query.companyViewId);
      return;
    }

    if (serverBreadcrumbs === null) {
      setBreadcrumbs(null);
      return;
    }

    if (!isEqual(breadcrumbs, serverBreadcrumbs) && serverBreadcrumbs && !serverBreadcrumbs.hasError) {
      setBreadcrumbs(serverBreadcrumbs);
    }
  }, [serverBreadcrumbs]);

  return {
    breadcrumbs,
    setBreadcrumbs,
    getIssuerBreadcrumbsReq,
  };
};

type BreadcrumbsContextType = ReturnType<typeof useBreadcrumbs>;

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>(null);

export const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error('useBreadcrumbsContext must be used within the BreadcrumbsContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
  breadcrumbs?: IBreadcrumb[] | { hasError: boolean },
}

const BreadcrumbsContextProvider = ({ children, breadcrumbs }: IProps) => (
  <BreadcrumbsContext.Provider value={ useBreadcrumbs(breadcrumbs) }>
    { children }
  </BreadcrumbsContext.Provider>
);

export default BreadcrumbsContextProvider;
